import { DEFAULT_DESCRIPTION, DEFAULT_OG_IMAGE, DEFAULT_TITLE, MAIN_DOMAIN } from 'globalConstants';
import { isEqual } from 'lodash';
import type { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Fragment } from 'react';
import { detectDeviceByUserAgent } from 'utils';

const MasterHome = dynamic(() => import('components/desktop/master-home'));
const MobileMasterHome = dynamic(() => import('components/mobile/master-home'));

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req } = context;
  const { headers } = req;
  const device = detectDeviceByUserAgent(headers['user-agent']);

  return {
    props: {
      device,
    },
  };
};

interface Props {
  device?: 'mobile' | 'desktop';
}

const MasterHomePage: NextPage = (props: Props) => {
  const { device } = props;
  const title = DEFAULT_TITLE;
  const description = DEFAULT_DESCRIPTION;
  const ogUrl = MAIN_DOMAIN;
  const ogImage = DEFAULT_OG_IMAGE;

  return (
    <Fragment>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover'
        />
        <meta name='description' content={description} />
        <meta name='og:title' content={title} />
        <meta name='og:description' content={description} />
        <meta name='og:url' content={ogUrl} />
        <meta name='og:image' content={ogImage} />
        <title>{title}</title>
      </Head>
      {isEqual(device, 'mobile') ? (
        <MobileMasterHome />
      ) : isEqual(device, 'desktop') ? (
        <MasterHome />
      ) : null}
    </Fragment>
  );
};

export default MasterHomePage;
